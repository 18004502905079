.loader {
    width: 80px;
    height: 80px;
    display: block;
    margin: 0 auto;
    animation-name: color;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

@keyframes color {
    0% {
        background-color: rgb(221, 131, 221);
    }
    10% {
        background-color: rgb(211, 182, 184);
    }
    20% {
        background-color: rgb(181, 214, 173);
    }
    30% {
        background-color: rgb(148, 199, 192);
    }
    40% {
        background-color: rgb(159, 170, 207);
    }
    50% {
        background-color: rgb(168, 151, 199);
    }
    60% {
        background-color: rgb(204, 150, 173);
    }
    70% {
        background-color: rgb(194, 176, 149);
    }
    80% {
        background-color: rgb(166, 199, 156);
    }
    90% {
        background-color: rgb(167, 210, 218);
    }
    100% {
        background-color: rgb(221, 131, 221);
    }              
  }