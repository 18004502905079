* {
    margin: 0;
    padding: 0;
    font-size: 62.5%;
    font-family: 'Abel', sans-serif;

    @include respond(medium) {
        font-size: 60%;
    }
    @include respond(small) {
        font-size: 57.5%;
    }

}

h1, h2, h3, h4, h5, h6, a {
    font-family: 'Montserrat', sans-serif;
}

.body {   

    background-color: var(--colour-background);
    color: var(--colour-text);

    &__content {
        //padding: 0 1rem 0;
        padding:  $navigationBar-height + 30px 2rem;
        margin: 0 auto;
        max-width: $max-width;
        display: grid;
        grid-template-columns: [left-section-start] 160px [left-ection-end center-section-start] 1fr [center-section-end right-section-start] 160px [right-section-end]; 
        
        @include respond(medium) {
            padding: 25px;
            grid-template-columns: [left-section-start] 0px [left-ection-end center-section-start] 1fr [center-section-end right-section-start] 0px [right-section-end]; 
        }
    }

    &__center {
        grid-column: center-section-start / center-section-end;

    }

    &__left {
        grid-column: left-section-start / left-section-end;
        margin-right: 30px;

        @include respond(medium) {
            display: none;
        }

    }

    &__right {
        grid-column: right-section-start / right-section-end;
        margin-left: 30px;

        @include respond(medium) {
            display: none;
        }
    }


    @include respond(large) {
        &__content {
            margin-top: $navigationBar-height;
        } 
    }
}

.noScroll {
    height: 100%;
    overflow: hidden;    
}

.text {
    &--medium {
        font-size: 1.6rem;
    }
}