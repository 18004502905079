.welcome-panel {
    width: 100%;
    height: 200px;
    background-image: url('/home/ash/Pictures/wp.png');

    &__greeting {
        font-size: 2rem;
    }

}

.side {
    position: sticky;
    top: 0;
}