.content {
    //margin: 0 150px;
    display: grid;
    gap: 30px;
    grid-template-columns: [content-timeline-start] 2fr [content-timeline-end content-preview-start] 1fr [content-preview-end];
    grid-template-rows: [content-focus-start] 1fr [content-focus-end];

    @include respond(medium) {
        gap: 0;

    }

    @include respond(medium) {
        grid-template-columns: 1fr;
    }
    &__timeline {
        grid-column: content-timeline-start / content-timeline-end;
        grid-row: content-focus-start / content-focus-end;
        overflow: auto;  
           
    }
    &__preview {
        grid-column: content-preview-start / content-preview-end; 
        grid-row: content-focus-start / content-focus-end; 

        @include respond(medium) {
            display: none;
        }
    }
    &__items {
        grid-column: content-timeline-start / span content-preview-end; 
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));    

    }
}

.content-item {
    
    &__title {
        font-family: $font-title-family;
        font-size: $font-title-size;

        &:active,
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    &__source--stacked {

    }

    &__source {
        display: flex;
        align-items: center;
        gap: 10px;

        @include respond(small) {
            align-items: flex-start;
            justify-content: center;
        }
    }

}

.content-item-huge {
    margin: 10px;
    //padding: 50px 50px;
    padding: 0 30px;
    //box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.15);  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    //border-bottom: 1px solid black;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    & p, time, span {
        font-size: $font-medium;
    }

    &__title {
        margin-bottom: -5px;
        font-size: 1.8rem;
    }

    &__action {
        display: flex;
        justify-content: center;
        //padding: 20px 30px;
        gap: 50px;

         svg {
            fill: rgb(0, 0, 0);
        }

    }

    &__like {
        font-size: 2rem;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    &__author {
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;

        & svg {
            height: 5px;
            width: 5px;
            margin: 0px 7px;
            fill: gray;
        }

        
        @include respond(small) {
            flex-direction: column;
            align-items: center;
            gap: 5px;
            margin: 0;

            & svg {
                display: none;
            }
        }

    }
    &__description {
        padding: 20px 15px;
       // font-style: italic;
    }

    &__header {
        font-size: 2rem;
    }



    &__image {
        display: block;
        max-width: 100%;
        margin: 0 auto;
        border-radius: 15px;
    }

    &__icon {

        &-link {
            width: 20px;
  
        }

        &-source {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }

    &__source {
        display: flex;
        align-items: center;

        & span {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }

    &:not(:last-child) {
        //margin-bottom: 50px;
    }

    @include respond(small) {
        padding: 0;
        text-align: center;
        box-shadow: none;
        border-radius: 0;

        border-bottom: 1px solid lightgray;

        & svg {
            //display: none;
        }
    }
}


.youtube {
    
    &__embedded {
        width: 100%;
        height: 422px;

    }
}
.preview-item--sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 110px;
}

.content-item-snippet { 
    color: var(--colour-text);    
    display: flex;
    margin-bottom: 15px;
    border-left: 1px solid var(--colour-background); 

    
    & p, time, span {
        font-size: $font-medium;
    }

    &__body {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        gap: 10px;
        margin-bottom: 15px;
    }

    &__text {
        font-size: 1.6rem;
        color: var(--colour-text);
    }

    &--highlighted {
        border-left: 1px solid red;
    }

}

.circle {

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 10px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background-color: rgb(0, 0, 0);
    fill: white;
    cursor: pointer;
    transition: ease-in .05s;

    &:active,
    &:hover {
        background-color: rgb(201, 201, 201);
        fill: black;
    }
}

.content-item-divider {
    margin: 30px auto;
    height: 1px;
    width: 75%;
    //background-color: rgb(204, 204, 204);
}

$content-focus-padding: 0 30px;

.content-focus {

    &__full {
        width: 800px;
        margin: 0 auto;
    }

    &__body {
        position: absolute;
        background: rgb(255, 255, 255);
        overflow: auto;
        border-radius: 4px;
        outline: none;
        border-radius: 20px;
        width: 800px;

        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        height: 90vh;
        overflow-y: hidden;

        @include respond(small) {
            width: 100%;
            height: 100%;
            border-radius: 0
        }
    }

    &__action {
        display: flex;
        justify-content: space-between;
        border-bottom: black 1px;
        margin: 25px 0;
        padding: $content-focus-padding;

        & div {
            display: flex;
            align-items: center;
            gap: 15px;
        }

        & svg {
            height: 20px;
            width: 20px;
        }

        & span {
            font-size: 1.2rem;

        }

        &--like {
            grid-column: 10;
        }

        &--share {
            grid-column: 11;
            justify-self: start;
        }

        &--close {
            grid-column: 12;
            margin-left: 25px;
        }
    }

    &__content-body {
        overflow-y: scroll;
        height: inherit;
        padding: $content-focus-padding;

    }

    &__content {
        // display: grid;
        // grid-template-columns: 2fr 1fr;
        // gap: 4rem;

        & h1 {
            font-size: 2.4rem;
            text-align: start;
        }

        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    

    &__backdrop {
        position: fixed;
        inset: 0px;
        background: rgba(0, 0, 0, 0.5);
        z-index: 6;
    }

    &__source {
        display: flex;
        align-items: center;
        gap: 10px;

        & img, svg {
            height: 30px;
            width: 30px;
        }

        & span, time {
            font-size: 1.4rem;
            display: block;
        }
    }

    &__image {
        width: 100%;
        border-radius: 20px;
        padding: 0 100px;
    }

    &__info {
        font-size: 1.6rem;
        display: block;
        padding: 0 100px;

        @include respond(small) {
            padding: 0;
        }
    }

    &__link-button {
        height: 40px;
        width: 250px;
        border-radius: 10px;
        border: none;
        font-size: 1.6rem;
        background-image: linear-gradient(to right, #674396, #CF7596);
        color: white;

        & :hover {
            background-image: linear-gradient(to right, #313131, #9e4e6b);
        }
    }

    &__details {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
    }

    &__similar {
        margin-top: 6rem;

        & h2 {
            font-size: 2.4rem;
            text-align: center;

            @include respond(small) {
                text-align: left;
            }
        }
    }

    &__similar-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        gap: 2rem;


        @include respond(small) {
            display: flex;
            flex-direction: column;
        }
    }

}

.content-focus-skeleton {
    &__title {
        font-size: 1.6rem;
        color: var(--colour-text);
        margin-bottom: 2rem;
    }

    &__image {
        height: 400px;
        width: 100%;
    }

    &__source {
        height: 1.2rem;
        width: 200px !important;
        margin: 5px 0;
    }

    &__link-button {
        height: 50px !important;
        width: 350px !important;
        border-radius: 20px;
        border: none;
        font-size: 1.6rem;
    }
}