.content {
    //margin: 0 150px;
    display: grid;
    gap: 30px;
    grid-template-columns: [content-timeline-start] 2fr [content-timeline-end content-preview-start] 1fr [content-preview-end];
    grid-template-rows: [content-focus-start] 1fr [content-focus-end];

    @include respond(medium) {
        gap: 0;

    }

    @include respond(medium) {
        grid-template-columns: 1fr;
    }
    &__timeline {
        grid-column: content-timeline-start / content-timeline-end;
        grid-row: content-focus-start / content-focus-end;
        overflow: auto;  
           
    }
    &__preview {
        grid-column: content-preview-start / content-preview-end; 
        grid-row: content-focus-start / content-focus-end; 

        @include respond(medium) {
            display: none;
        }
    }
    &__items {
        grid-column: content-timeline-start / span content-preview-end; 
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));    

    }
}

.content-item {

    &__source {

    }
}

.content-item-stack {
    margin: 10px;
    padding: 50px 50px;
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.15);  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    //border-bottom: 1px solid black;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    & p, time, span {
        font-size: $font-medium;
    }

    &__title {
        font-size: 1.6rem;
        //font-family: 'IBM Plex Serif', serif;
//        font-size: 1.6rem;

        &:active,
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    &__action {
        display: flex;
        justify-content: center;
        padding: 20px 30px;
        gap: 50px;

         svg {
            fill: grey;
        }

    }

    &__like {
        font-size: 2rem;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    &__author {
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;

        & svg {
            height: 5px;
            width: 5px;
            margin: 0px 7px;
            fill: gray;
        }

        
        @include respond(small) {
            flex-direction: column;
            align-items: center;
            gap: 5px;
            margin: 0;

            & svg {
                display: none;
            }
        }

    }
    &__description {
        padding: 20px 15px;
       // font-style: italic;
    }

    &__header {
        font-size: 2rem;
    }

    &__source {
        display: flex;
        align-items: center;

        @include respond(small) {
            align-items: flex-start;
            justify-content: center;
        }

    }

    &__image {
        display: block;
        max-width: 100%;
        margin: 0 auto;
        border-radius: 15px;
    }

    &__icon {

        &-link {
            width: 20px;
  
        }

        &-source {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }

    &:not(:last-child) {
        margin-bottom: 50px;

    }

    @include respond(small) {
        padding: 0;
        text-align: center;
        box-shadow: none;
        border-radius: 0;

        border-bottom: 1px solid lightgray;

        & svg {
            //display: none;
        }
    }
}


.youtube {
    
    &__embedded {
        width: 100%;
        height: 422px;

    }
}
.preview-item--sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 110px;
}

.content-item-preview {
    //position: -webkit-sticky;
    //position: sticky;
    //top: 0;
    color: var(--colour-text);
    
    display: flex;
    margin-bottom: 15px;

    & p, time, span {
        font-size: $font-medium;
    }

    &__img {
        width: 200px;
        object-fit: none;
        display: none;
    }

    &__title {
        font-size: 1.6rem;
        color: var(--colour-text);
    }

    &__body {
        margin-left: 10px;
    }

    &__text {
        font-size: 1.6rem;
        color: var(--colour-text);
    }

    &__info {

    }
}

.circle {

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 10px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background-color: rgb(0, 0, 0);
    fill: white;
    cursor: pointer;
    transition: ease-in .05s;

    &:active,
    &:hover {
        background-color: rgb(201, 201, 201);
        fill: black;
    }
}