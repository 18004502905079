.navigation {
    z-index: 5;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    width: 100%;
    padding: 25px;
    //transition: all 0.3s ease-in-out;
    max-width: $max-width;
    background-color: var(--colour-background);
    
    //box-shadow: 0px 0px 15px 1px rgb(211, 211, 202);  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */

    //background-color: rbga($color(var(--colour-background)),$alpha(.8));
    border-bottom: #000000 1px;

    &--lighten {
        padding: 5px 0;
    }

    &__logo {
        //font-family: 'Caveat', cursive;
        font-size: 3rem;
        display: flex;
        align-items: center;
        color: inherit;


        &--text {

        }

        &__logo {
            background-color: rgb(221, 131, 221);
            margin-right: 15px;
            padding: 10px;
            padding-bottom: 5px;
            animation-name: color;
            animation-duration: 30s;
            animation-iteration-count: infinite;
            border-radius: 10px;

        }

        &--fast {
            width: 80px;
            height: 80px;
            display: block;
            margin: 0 auto;
            animation-name: color;
            animation-duration: 3s;
            animation-iteration-count: infinite;
        }

        &:active,
        &:hover {
            cursor: pointer;
        }
    }

    &__links {
        display: flex;
        align-items: center;
        gap: 25px;
        margin-right: 30px;
    }

    &__link {
        display: flex;
        align-items: center;
        gap: 15px;

        font-size: 1.6rem;
        text-decoration: none;
        color: var(--colour-text);

        & svg {
            height: 20px;
            width: 20px;
        }

        &--active {
            font-weight: bold;
        }

        @include respond(medium) {
            margin-bottom: 16px;
        }
        
        transition: .05s ease-in;    

        &:hover {
            color: $colour-red;
        }
    }

}

.navigation-full {

    display: flex;
    gap: 35px;
    box-sizing: border-box;


    & > div {
        display: flex;
        align-items: center;
        gap: 25px;
    }

    &__right {
        width: 100%;
        justify-content: flex-end;
    }
}

.logo {
    width: 35px;
    height: 35px;
}

.navigation-mini {

    padding: 25px;
    position: relative;


    &__top {
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;         /* Opera/IE 8+ */        

        z-index: 5;
        position: fixed;
        top: 0;
        left: 0;
        padding: 5px 30px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100vw;
        height: 50px;
        background-color: var(--colour-background);;

        & svg {
            height: 20px;
            width: 20px;
            fill: var(--colour-text);
        }

    }

    &__logo {
        height: 40px;
        width: 40px;
        animation-name: color;
        animation-duration: 60s;
        animation-iteration-count: infinite;
        border-radius: 10px;

        & img {
            height: 25px;
            width: 25px;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__menuBackdrop {
        display: none;
        position: fixed;
        z-index: 4;
        top: 0;
        height: 100vh;
        width: 100vw;
        background-color: rgba($color: #000000, $alpha: .5);

        &--open {
            left: 0;
            animation-name: fadeIn;
            animation-duration: .5s;
            display: block;
        }
    }

    &__menu {
        position: fixed;
        z-index: 4;
        top: 0;
        left: -100vh;
        width: 50vw;
        height: 100vh;
        padding: 100px 30px;

        display: flex;
        flex-direction: column;
;
        background-color: var(--colour-background);;

        &--open {
            left: 0;
            animation-name: leftIn;
            animation-duration: .5s;
        }
    }

    &__link {
        font-size: 1.6rem;
        padding: 5px;
        //margin: 0 10px;
        text-decoration: none;
        color: #000000;

        &--icon {
            height: 30px;
            width: 30px;
        }
    }



    &__category {
        font-size: 1.6rem;
        text-decoration: none;
        margin-right: 15px;
        padding: 5px 15px;
        border-radius: 10px;
        background-color: #ffffff;
        color: rgb(90, 55, 90);

        &:first {
            margin-top: 15px;
        }
    }


}

.linkIcon {
    //margin-top: -25px;

    &--diamond {
        width: 15px;
        margin-bottom: 3px;
        margin-right: -10px;
        margin-left: 5px;
    }

    &--fire {
        width: 40px;
        margin-bottom: 8px;
        margin-right: -20px;
    }
}

.search-bar {
    display: flex;
    align-items: center;
    gap: 35px;
    width: 100%;

    & input {
        outline: none;
        border: none;
        background-color: var(--colour-background);
        border-bottom: solid 1px var(--colour-text);
        font-size: 1.6rem;
        width: 100%;
        padding: 5px 15px
    }
}

@keyframes lighten {
    0% {
        height: auto;

    }

    100% {
        height: 30px;

    }
}


@keyframes color {
    0% {
        background-color: rgb(221, 131, 221);
    }
    10% {
        background-color: rgb(211, 182, 184);
    }
    20% {
        background-color: rgb(181, 214, 173);
    }
    30% {
        background-color: rgb(148, 199, 192);
    }
    40% {
        background-color: rgb(159, 170, 207);
    }
    50% {
        background-color: rgb(168, 151, 199);
    }
    60% {
        background-color: rgb(204, 150, 173);
    }
    70% {
        background-color: rgb(194, 176, 149);
    }
    80% {
        background-color: rgb(166, 199, 156);
    }
    90% {
        background-color: rgb(167, 210, 218);
    }
    100% {
        background-color: rgb(221, 131, 221);
    }
    
              
  }

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes leftIn {
    0% {
        transform: translateX(-80vw);
    }
    100% {
        transform: translateX(0);
    }
}
