.homeItemMain {
    overflow: hidden;

    &__heading {
        font-size: 3rem;
    }

    &__section {
        display: grid;
        grid-template-columns: repeat(1, [col-start] 1fr [col-end]);
        grid-template-rows: repeat(4, [row-start] 250px [row-end]);


        &--a {
            grid-column: col-start 1 / col-end 1;
            grid-row: row-start 1 / row-end 1;
        }

        &--b {
            grid-column: col-start 1 / col-end 1;
            grid-row: row-start 2 / row-end 1;
        }

        &--c {
            grid-column: col-start 1 / col-end 1;
            grid-row: row-start 3 / row-end 3;
        }

        &--d {
            grid-column: col-start 1 / col-end 1;
            grid-row: row-start 4 / row-end 4;  
        }
    }

    @include respond(medium) {
        &__section {
            display: grid;
            grid-template-columns: repeat(2, [col-start] 1fr [col-end]);
            grid-template-rows: repeat(2, [row-start] 1fr [row-end]);
    
    
            &--a {
                grid-column: col-start 1 / col-end 1;
                grid-row: row-start 1 / row-end 1;
            }
    
            &--b {
                grid-column: col-start 2 / col-end 2;
                grid-row: row-start 1 / row-end 1;
            }
    
            &--c {
                grid-column: col-start 1 / col-end 1;
                grid-row: row-start 2 / row-end 2;
            }
    
            &--d {
                grid-column: col-start 2 / col-end 2;
                grid-row: row-start 2 / row-end 2;  
            }
        }
    }

    @include respond(large) {
        &__section {
            display: grid;
            grid-template-columns: repeat(6, [col-start] 1fr [col-end]);
            grid-template-rows: repeat(2, [row-start] 230px [row-end]);
            background-color: #212121;

            &--a {
                grid-column: col-start 1 / col-end 2;
                grid-row: row-start 1 / row-end 2;
            }

            &--b {
                grid-column: col-start 3 / col-end 4;
                grid-row: row-start 1 / row-end 1;
            }

            &--c {
                grid-column: col-start 3 / col-end 4;
                grid-row: row-start 2 / row-end 2;
            }

            &--d {
                grid-column: col-start 5 / col-end 6;
                grid-row: row-start 1 / row-end 2;  
            }
        }
    }
}

.HomeItemMainArticle {
    width: 100%;
    height: 100%;
    position:relative;
    float: left;

    &__img {
        width: 100%;
        height: 100%;

    
        &__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__text {
        position: absolute;
        margin: 20px;
        font-size: 2.5rem;
        font-weight: 600;
        //text-align: center;
        color: white;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;

        &__tag {
            display: inline-block;
            padding: 5px;
            margin-bottom: 5px;
            font-size: 1.5rem;
            font-weight: inherit;
            background-color:  rgb(255, 86, 86);
        }

        &__source {
            font-size: 1.5rem;
            font-weight: 300;
            margin-bottom: 10px;
        }

        &__title {
            font-size: inherit;
            font-weight: inherit;
        }
    }



    &::before {
        content: '';
        position:absolute;
        top:50%;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient( to bottom, rgba(255, 255, 255, 0) 0%, rgba(0,0,0,.6) 100%);
        z-index: 1;
    }

    &:hover {
        cursor: pointer;
    }
}


.scoreHub {
    color: whitesmoke;
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    justify-self: center;

    &__date {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 6.5rem;

        &--month {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 20px;
            font-size: 3rem;
            &-day {
                margin-bottom: -15px;
            }

            
        }
    }

    &__details {
        font-size: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__main {
        padding: 10px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        &__club {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__details {
        }
    }
}