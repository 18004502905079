$card-action-opacity-default: 1;
$card-action-opacity-active: 1;

.content-card-main {
    margin: 30px 0px;
    //box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.2);  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    background-color: inherit !important;
    
    //display: flex;
    //flex-direction: column;

    text-decoration: none;
    color: inherit;
    //border-radius: 15px;

    overflow: hidden;
    transition: ease .2s;
    z-index: 1;

    & p, time, span, h4, a {
        text-align: center;
        font-size: 15px;
    }

    & h3 {
        //font-family: 'IBM Plex Serif', serif;
        //font-family: 'Lora', serif;
        font-size: 1.6rem;
        margin: 10px 0;
        text-align: start;
    }

    & img {
        border-radius: 20px;
        transition: ease .2s;
    }

    &__time {
        display: flex;
        gap: 10px;
        align-items: center;

        & svg {
            height: 7px;
            width: 7px;
            fill: var(--color-accent-lighter) !important;
        }

        & time {
            color: var(--color-accent-light);
        }
    }

    &__source {
        margin-bottom: 10px;
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 10px;

        & svg, img {
            width: 20px;
            height: 20px;
        }

        & p, time {
            color: var(--color-accent-light);
        }

        & > .content-card-main__dot {
            height: 5px;
            width: 5px;
            fill: var(--color-accent-light) !important;
        }

    }

    &__content {
        flex: 1;
        height: 100%;
        padding: 30px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 15px;
    }

    &__action {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        opacity: $card-action-opacity-default;
        transition: ease .2s;
    }

    &__like {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    &__details {
        z-index: 2;

        & a {
            color: inherit;
            text-decoration: none;

            & :hover {
                text-decoration: underline;
            }
        }
    }

    &__media {
        z-index: 1;
        flex: 1;
        overflow: hidden;
    }

    &__image {
        width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: cover;

        &--blur {
            object-fit: none;
            filter: blur(50px);
            transform: none !important;
        }

        &--youtube-image {
            position: absolute;
            top:50px;
            left: 0;
            width: 100%;
            height: 150px;
            object-fit: cover;
            border-radius: 0% !important;
        }
    
        &--youtube-wrapper {
            position: relative;
            overflow: hidden;
            //border-radius: 15px 15px 0px 0px;
            border-radius: 15px;
        }

    }

    &__share-links {
        display: flex;
        gap: 10px;
        justify-content: center;
        opacity: $card-action-opacity-default;
        transition: ease .2s;

        & svg {
            height: 25px;
            width: 25px;
            border-radius: 50%;
        }
    }

    &__theme {
        &--dark {
            
        }
    }

    &:hover {
        h3 {
            //text-decoration: underline;
        }

        img {
            transform: scale(1.1);
            border-radius: 0px;
        }

        & .content-card-main__action, .content-card-main__share-links {
            opacity: 1;
        }
    }



}