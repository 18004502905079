$background-light: #fffffd;
$background-dark: #000000;

$text-light: black;
$text-dark: #fffffd;

:root {
    --colour-background: #fffffd;
    --colour-text: black;
    --color-accent-light: grey;
    --color-accent-lighter: lightgrey;

}