$navigationBar-height: 70px;
$navigationBar-height-desktop: 55px;

$colour-red: #ef5350;

$max-width: 1650px;

$font-medium: 1.5rem;
$font-large: 1.6rem;


$font-title-family: 'Montserrat', sans-serif;
$font-title-size: 1.6rem;