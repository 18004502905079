.link-undecorated {
    text-decoration: none;
    color: black;
}

.input {

    &--button {
        height: 20px;
        width: 20px;
        transition: ease 0.15s;
        fill: var(--colour-text);
        cursor: pointer;
        // /border-radius: 10px;
        //padding: 7px;

        &:hover {
            transform: scale(1.1);
            //background-color: rgb(240, 240, 240);
        }

        &:active {
            transform: scale(0.95);
            //background-color: rgb(247, 247, 247);
        }

        &__selected {
            fill: rgb(255, 0, 0) !important;
            transform:  scale(1.05);
        }
    }

}

.icon {
    &--smaller{
        height: 5px;
        width: 5px;
    }

    &--medium {
        height: 20px;
        width: 20px;
    }


    &--dot {
        height: 5px;
        width: 5px;
        fill: grey;
    }
}